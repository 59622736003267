<template>
    <div class="card card-danger">
        <div class="card-header">
            <h3 class="card-title">{{ $t('general.acceso') }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.usuario') }}</label>
                        <input type="text" class="form-control nombre_completo dato" id="nombre_completo" placeholder="">
                    </div>
                    
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.clave') }}</label>
                        <input type="password" class="form-control nombre_completo dato" id="nombre_completo" placeholder="">
                    </div>   
                </div>
            </div>
            <!-- Se omite el acceso de email/clave ya que no se usa ni est�n contemplado en el acceso al webmail -->
        </div>
    </div>
</template>