<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div v-if="(id > 0)" class="col-sm-6">
                    <h1>{{ $t('general.comercial') }}</h1>
                </div>
                <div v-else class="col-sm-6">
                    <h1>Nuevo comercial</h1>
                </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ comercial.nombre_comercial}}</h3>
                            <p class="text-muted text-center text-white">{{comercial.ciudad_comercial}}</p>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>{{ $t('general.companias') }}</b> <a class="float-right">5</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                    <h5>{{ $t('general.ultimamodificacion') }}</h5>
                    <p>{{ comercial.fecha_actualiza_comercial }}</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_comercial" data-toggle="tab">{{ $t('general.datos') }}</a></li>  
                                <li class="nav-item"><a class="nav-link" href="#companyias_comercial" data-toggle="tab">{{ $t('general.companias') }}</a></li>  
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_comercial">
                            <comercial-datos
                            :comercial="comercial"
                            :id="comercial.id" />
                        </div>
                        <div v-if="(id > 0)" class="tab-pane" id="companyias_comercial">
                            <comercial-companyias />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import ComercialDatos from './datos/ComercialDatos.vue'
import ComercialCompanyias from './companyias/ComercialCompanyias.vue'
import { PwgsApi } from '../../../services/PwgsApi'
export default {
    props: ['id'],
    components: {
        'comercial-datos': ComercialDatos,
        'comercial-companyias': ComercialCompanyias,
    },
    data() {
        return {
            comercial: [],
        }
    },
    methods: {
        async obtenerComerciales() {
            /* get  modulos/pwgsapi/index.php/comerciales/:id */
            const api = new PwgsApi;
            const datos = await api.get('comerciales/' + this.$props.id);
            this.comercial = datos.datos;
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas; 
            let nombrecomercial = this.comercial.nombre_comercial;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/comerciales/'+ventanaid) {
                    this.$store.getters.ventanas[i].name = nombrecomercial;
                    this.$store.getters.ventanas[i].alt = "Comercial: "+ this.comercial.nombre_comercial + " || " + this.comercial.direccion_comercial;
                }
            }
            //
        },
    },
    mounted() {
        this.obtenerComerciales();
    },
    watch: {
        id() {
            this.obtenerComerciales();
        },
        $route() {
            //
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;

            let nombrecomercial = this.comercial.nombre_comercial;

            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/comerciales/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombrecomercial;
                    this.$store.getters.ventanas[i].alt = "Comercial: " + this.comercial.nombre_comercial + " || " + this.comercial.direccion_comercial;
                }
            }
            //
        }
    }
}
</script>
