<template>
    <div class="row">
        <div class="col-md-5">
            <div class="card card-warning">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.compania') }}</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="input-group">
                                <select class="custom-select form-control-sm select2" v-model="seleccionado">
                                    <option v-for="compañia in listado_compañias" :key="compañia.idcompañia" :value="compañia.id_compañia">{{compañia.nombre}} ({{compañia.prefijo}})</option>
                                    </select>
                                </div>
                            </div>
                        <div class="col-md-1">
                            <button class="btn btn-primary">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-sm table-striped">
                                <tbody>
                                    <tr>
                                        <td>Zurich (ZU)</td>
                                        <td><button type="button" class="btn btn-block btn-outline-danger btn-xs">{{ $t('general.eliminar') }}</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'comercial'],
    data() {
        return {
            listado_compañias: [],
            seleccionado:'Selecciona compañía',
        }
    },
    methods: {
        async cargarDatosCompañias() {
            /* GET  modulos/pwgsapi/index.php/companias */
            const api = new PwgsApi;
            const datos = await api.get('companias/simple');
            this.listado_compañias = datos.datos;
       },
          },
    watch: {
        comercial() {
            this.cargarDatosCompañias();
        }
    },
    mounted() {
        this.cargarDatosCompañias();
    }
}
</script>