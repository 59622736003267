<template>
    <div class="row">
        <div class="col-md-6">
            <comercial-datos-datos 
                :id="id"
                :comercial="comercial" />
        </div>
        <div class="col-md-4" v-if="(id > 0)">
            <comercial-datos-economico
            :id="id"
            :comercial="comercial"/>
            <comercial-datos-acceso 
            :id="id"
            :comercial="comercial"/>
        </div>
    </div>
</template>
<script>
import ComercialDatosDatos from './ComercialDatosDatos.vue';
import ComercialDatosEconomico from './ComercialDatosEconomico.vue';
import ComercialDatosAcceso from './ComercialDatosAcceso.vue';
export default {
    props: ['id','comercial'],
    components: {
        'comercial-datos-datos': ComercialDatosDatos,
        'comercial-datos-economico': ComercialDatosEconomico,
        'comercial-datos-acceso': ComercialDatosAcceso,
    },
    data() {
        return {}
    },
}
</script>
