<template>
    <seccion-datos :titulo="comercialeconomicos" tipo="warning" @cambios="guardar()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.comision') }}</label>
                        <input type="number" v-model="comision" class="form-control  dato"  >
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.prefijofactura') }}</label>
                        <input type="text" v-model="pref_factura" class="form-control  dato" >
                    </div>                     
                </div>
            </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'comercial'], 
    data() {
        return {
            comision: '',
            pref_factura: '',
            comercialeconomicos:"Económicos"           
        }
    },


    methods: {
        cargarDatosComercial() {
            this.$store.dispatch('cambiarCargando', true);
            this.comision = this.comercial.comision_comercial;
            this.pref_factura = this.comercial.prefijo_factura_comercial;
            this.comercialeconomicos = this.$t("general.economicos");
            this.$store.dispatch('cambiarCargando', false);

        },
        async guardar() {
            /*  modulos/pwgsapi/index.php/comerciales/:id */
            const api = new PwgsApi;

            try {
                this.$store.dispatch('cambiarGuardando', true);
                const subidadatos = { comision_comercial: this.comision, prefijo_factura_comercial: this.pref_factura };
                await api.put('comerciales/' + this.comercial.id, subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

        }
    },
    watch: {
        comercial() {
            this.cargarDatosComercial();
        }

    },
    mounted() {
        this.cargarDatosComercial();

        
    }

}


</script>